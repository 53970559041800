import React from 'react'
import {Row, Col} from 'reactstrap'
import ComponentSelector from '../componentSelector'
import SanityImage from './sanityImage'

const RowSection = ({
  title,
  columnNumber,
  columns,
  background,
  colour,
  classNames = 'pageComponent',
  containerClassNames = 'container py-5',
  rowClassNames,
  backgroundImage
}) => (
  <div style={{background: background}} className={'position-relative overflow-hidden ' + classNames}>
    <SanityImage
      image={backgroundImage?.asset}
      alt={'background-image'}
      className="position-absolute"
      style={{filter: 'brightness(50%)', height: '100%'}}
    />
    <div className={containerClassNames}>
      <h3 className="text-center mb-4" style={{color: colour}}>
        {title}
      </h3>
      <Row className={rowClassNames}>
        {columns.map(column => (
          <Col
            xs={{ size: column.xs, offset: column.offsetXs }}
            md={{ size: column.md, offset: column.offsetMd }}
            lg={{ size: column.lg, offset: column.offsetLg }}
            xl={{ size: column.xl, offset: column.offsetXl }}
            className="mb-5"
            key={column._key}
          >
            {column.component && column.component.map(component => (
              <ComponentSelector _rawContent={[component]} key={component._key ? component._key : component.id}/>
            ))}
          </Col>
        ))}
      </Row>
    </div>
  </div>
)

export default RowSection
