import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Figure from './figure'
import Blockquote from './blockquote'
import Check from './check'
import Collapse from './collapse'
import Columns from './columns'
import Link from '../link'
import TextAlignment from './textAlignment'

function Small(props) {
  return React.createElement('span', {className: 'small'}, props.children)
}

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2>{props.children}</h2>

        case 'h3':
          return <h3>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'blockquote':
          return <Blockquote children={props.children}/>

        case 'figure':
          return <blockquote>{props.children}</blockquote>

        case 'check':
          return <Check>{props.children}</Check>

        default:
          // add spacing when editor return ""
          if (props.children.length === 1 && props.children[0] === "") {
            return <br/>
          } else {
            return <p>{props.children}</p>
          }
      }
    },
    figure (props) {
      return <Figure {...props.node}/>
    },
    collapse (props) {
      return <Collapse {...props.node}/>
    },
    textColumns (props) {
      return <Columns {...props.node}/>
    },
    embedHTML (props) {
      return <div dangerouslySetInnerHTML={{__html: props.node.html}}/>
    },
    textAlignment (props) {
      return <TextAlignment {...props.node}/>
    },
  },
  marks: {
    internalLink: ({mark, children}) => {
      const href = `/${mark.reference?.slug?.current}`
      return <Link to={href}>{children}</Link>
    },
    externalLink: ({mark, children}) => {
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    },
    small: Small
  },
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
