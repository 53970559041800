import React from "react"
import {Container, Row, Col} from 'reactstrap'
import {SiteConfig} from '../../data/siteConfig'
import { FaMapMarkerAlt, FaPhone, FaClock } from 'react-icons/fa';
import styled from 'styled-components'
import FormattedNumber from '../utils/formattedNumber'
import Link from '../link'

let ContactItemCol = styled(props => <Col {...props}/>)`
  &:hover {
    .circle {
      background: none;
      color: ${props => props.theme.primary};
      border: 2px solid ${props => props.theme.primary};
    }
  }
  .circle {
    background: ${props => props.theme.primary};
    color: white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    transition-duration: 0.5s;
    border: 2px solid ${props => props.theme.primary};
  }
`

let P = styled.p`
  color: ${props => props.theme.primary};
  font-weight: bold;
`

let SectionWrapperStyle = styled.div`
  .contact-item {
    margin-bottom: 1rem;
  }
`

let ContactItem = ({heading, text, route, Icon, type}) => (
  <ContactItemCol lg={type === 'page' ? "4" : '12'} className="contact-item">
    <Link to={route} className="unstyled">
      <div className="d-flex align-items-center mb-3 mb-lg-0">
        <div className="circle">
          <Icon/>
        </div>
        <div>
          <h5 className="m-0">{heading}</h5>
          <P className="m-0">{text}</P>
        </div>
      </div>
    </Link>
  </ContactItemCol>
)

const PageWrapper = ({children}) => (
  <div className="pageComponent">
    <Container className="py-4 py-lg-5">
      <Row>
        {children}
      </Row>
    </Container>
  </div>
)

const SectionWrapper = ({children}) => (
  <SectionWrapperStyle>
    {children}
  </SectionWrapperStyle>
)

let ContactBar = ({type}) => {
  let {phone, address, openingHours} = SiteConfig()
  let Wrapper = type === 'page' ? PageWrapper : SectionWrapper
  return (
    <Wrapper>
      <ContactItem
        heading="Make an Enquiry"
        text={<FormattedNumber number={phone}/>}
        route={`tel:${phone}`}
        Icon={FaPhone}
        type={type}
      />
      <ContactItem
        heading="Opening Hours"
        text={openingHours}
        route={`/contact-us`}
        Icon={FaClock}
        type={type}
      />
      <ContactItem
        heading="Our Location"
        text={address}
        route={`/contact-us`}
        Icon={FaMapMarkerAlt}
        type={type}
      />
    </Wrapper>
  )
}

export default ContactBar
