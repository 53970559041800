import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import styled from 'styled-components'
import SanityImage from './sanityImage'
import Buttons from './buttons'
import BlockContent from '../block-content'

let HeroStyle = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  @media ${props => props.theme.breakpoints.lg} {
    height: 600px;
  }
  .gatsby-image-wrapper {
    z-index: 0;
    min-height: 400px;
    @media ${props => props.theme.breakpoints.lg} {
      min-height: 600px;
    }
  }
`

let TextContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .h4 {
    font-size: 1rem;
  }
  h1, .h1 {
    font-size: 2rem;
  }
  @media ${props => props.theme.breakpoints.lg} {
    h1, .h1 {
      font-size: 2.5rem;
    }
  }
  @media ${props => props.theme.breakpoints.xl} {
    h1, .h1 {
      font-size: 3.25rem;
    }
    .h4 {
      font-size: 1.5rem;
    }
  }
  color: white;
`

function Hero ({
  buttons,
  bannerImage,
  subHeading,
  text,
  heading,
  baseClassNames,
  imageClassNames,
  rowClassNames,
  textClassNames
}) {
  return (
    <HeroStyle className={baseClassNames}>
      <TextContainer>
        <Container>
          <Row className={rowClassNames}>
            <Col md="7">
              <div className={textClassNames}>
                {text ? <BlockContent blocks={text || []} /> : (
                  <>
                    <h1 className="mb-4">{heading}</h1>
                    <h3 className="mb-4 h4">{subHeading}</h3>
                  </>
                )}
                <Buttons buttons={buttons}/>
              </div>
            </Col>
          </Row>
        </Container>
      </TextContainer>
      <SanityImage
        image={bannerImage.asset}
        alt={heading}
        className={imageClassNames}
      />
    </HeroStyle>
  )
}

export default Hero
