import React from 'react'
import {FaCheckCircle} from 'react-icons/fa'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

let StyledCheck = styled.div`
  padding-top: 0.5rem;
  .inner {
    display: flex;
    margin-bottom: 0.5rem;
    padding-top: 0;
    overflow: hidden;
    svg {
      margin-right: 1rem;
    }
    p {
      width: 95%;
      margin-bottom: 0;
    }
  }
`

const Check = props => (
  <StyledCheck className="check">
    <div className="inner">
      <FaCheckCircle
        size={25}
        style={{ userSelect: "none" }}
        contentEditable={false}
        color={props.theme.primary}
      />
      <p>
        {props.children}
      </p>
    </div>
  </StyledCheck>
)

export default withTheme(Check)
