import React from 'react'
import { getGatsbyImageData } from "gatsby-source-sanity";
import {GatsbyImage} from 'gatsby-plugin-image'
import sanityConfig from '../sanityConfig'

const SanityImage = ({
  image,
  alt,
  style = {marginBottom: '1rem'},
  ...props
}) => {
  const imageData = getGatsbyImageData(
    image,
    { maxWidth: 960 },
    sanityConfig
  );

  return (
    <>
      {imageData && <GatsbyImage
        image={imageData}
        alt={alt}
        style={style}
        {...props}
      />}
    </>
  )
}

export default SanityImage
