import React from 'react'
import {Container} from 'reactstrap'
import BlockContent from './block-content'
import {
  Hero,
  ImageSection,
  ButtonCollection,
  Form,
  Row,
  Sponsor,
  Card,
  SanityImage,
  Btn,
  RepeatableSection,
  GoogleMap
} from './pageComponents'
import {
  BlogPosts,
  ContactBar,
  GoogleReviews,
  ContactLinks
} from './uiComponents'

const ComponentSelector = ({_rawContent, type}) => (
  <>
    {_rawContent && _rawContent.length > 0 && _rawContent.filter(c => c != null).map(c => {
      let key = c._key ? c._key : c._id
      switch (c._type) {
        case "textSection":
          if (type === 'page') {
            return c && (
              <div className="pageComponent" key={key}>
                <Container className="py-5">
                  <BlockContent blocks={c.text || []} />
                </Container>
              </div>
            )
          }
          return c && (
            <BlockContent key={key} blocks={c.text || []} />
          )
        case "hero":
          return <Hero {...c} key={key}/>
        case "imageSection":
          return <ImageSection {...c} key={key}/>
        case "buttonCollection":
          return <ButtonCollection {...c} key={key}/>
        case "form":
          return <Form {...c} type={type} key={key}/>
        case "repeatableSection":
          return <RepeatableSection {...c} type={type} key={key}/>
        case "row":
          return <Row {...c} key={key}/>
        case "sponsor":
          return <Sponsor {...c} key={key}/>
        case "card":
          return <Card {...c} key={key}/>
        case "bannerImage":
          return <SanityImage image={c} alt={c.alt} key={key}/>
        case "cta":
          return <Btn {...c} key={key}/>
        case "googleMap":
          return <GoogleMap {...c} type={type} key={key}/>
        case "uiComponentRef":
          switch (c.name) {
            case "blog":
              return <BlogPosts {...c} key={key}/>
            case "googleMap":
              return <GoogleMap {...c} type={type} key={key}/>
            case "contactBar":
              return <ContactBar type={type} key={key}/>
            case "googleReviews":
              return <GoogleReviews {...c} type={type} key={key}/>
            case "contactLinks":
              return <ContactLinks {...c} type={type} key={key}/>
          }
      }
    })}
  </>
)

export default ComponentSelector
