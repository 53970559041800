import React from 'react'
import styled, {withTheme} from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Container} from 'reactstrap'
import Buttons from './pageComponents/buttons'

let BannerStyle = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  .gatsby-image-wrapper {
    min-height: 450px;
  }
  @media ${props => props.theme.breakpoints.lg} {
    height: ${props => props.theme.page.header.height};
    .gatsby-image-wrapper {
      min-height: 650px;
    }
  }
`

let TextContainer = styled.div`
  text-align: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  h1 {
    color: white;
    @media ${props => props.theme.breakpoints.lg} {
      font-size: 4.5rem;
    }
  }
`

let ImageBanner = ({title, image, buttons, theme}) => (
  <BannerStyle>
    <TextContainer>
      <Container>
        <h1>
          {title}
        </h1>
        <Buttons buttons={buttons}/>
      </Container>
    </TextContainer>
    {image && image.asset ? <GatsbyImage
      image={image.asset.gatsbyImageData}
      alt={image.alt}
      style={{width: '100%', maxHeight: '600px', position: theme.page.header.position}}
    /> : ''}
  </BannerStyle>
)

export default withTheme(ImageBanner)
