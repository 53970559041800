import React from 'react'
import ImageBanner from './imageBanner'
import ComponentSelector from './componentSelector'

let Page = ({_rawContent, title, pageHeader, bannerImage, _rawButtons}) => (
  <div>
    {pageHeader ? <ImageBanner title={title} image={bannerImage} buttons={_rawButtons}/> : ''}
    <ComponentSelector _rawContent={_rawContent} type="page"/>
  </div>
)

export default Page
