import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import SanityImage from './sanityImage'
import BlockContent from '../block-content'

const ImageSection = ({heading, image, text, imagePosition, imageText}) => (
  <div className="pageComponent imageSection">
    <Container className="py-5">
      <Row>
        <Col md={{size:"6", order: imagePosition === 'right' ? 1 : 2}}>
          <h4>{heading}</h4>
          <BlockContent blocks={text || []} />
        </Col>
        <Col md={{size:"6", order: imagePosition === 'right' ? 2 : 1}} className="text-center">
          {image ? <SanityImage
            image={image}
            alt={image.alt}
          /> : ''}
          {imageText ? <p className="mt-3 ml-3 text-secondary">{imageText}</p> : ''}
        </Col>
      </Row>
    </Container>
  </div>
)

export default ImageSection
