import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Link from '../link'
import SanityImage from './sanityImage'
import styled from 'styled-components'

let ImageLinkContainer = styled.div`
  position: relative;
  > div {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
  }
  a {
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
  .gatsby-image-wrapper {
    height: 100%;
    transition-duration: 0.5s;
  }
  h4 {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    transition-duration: 0.5s;
    margin: 0;
    color: white;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.5rem;
  }
  &:hover {
    h4 {
      background: rgba(0, 0, 0, 0.5);
    }
    .gatsby-image-wrapper {
      transform: scale(1.05);
    }
  }
`

const ImageLink = ({imageLink}) => (
  <Col xs={6} md={6} lg={3} className="mb-3">
    <ImageLinkContainer>
      <div>
        <Link to={imageLink.pageRoute ? '/'+imageLink.pageRoute.slug.current : imageLink.route}>
          {(imageLink?.image || imageLink?.pageRoute?.bannerImage) && <SanityImage
            image={imageLink.image ? imageLink.image : imageLink.pageRoute.bannerImage}
            alt={imageLink.title}
          />}
          <h4 className="text-center">
            {imageLink.title ? imageLink.title : imageLink.pageRoute.title}
          </h4>
        </Link>
      </div>
    </ImageLinkContainer>
  </Col>
)

const ButtonCollection = ({imageLinks, heading}) => (
  <div className="pageComponent">
    <Container className="py-5">
      <h2 className="text-center mb-5">{heading}</h2>
      <Row className="d-flex justify-content-center">
        {imageLinks.map(imageLink => <ImageLink imageLink={imageLink} key={imageLink._key}/>)}
      </Row>
    </Container>
  </div>
)

export default ButtonCollection
