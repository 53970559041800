import React from 'react'
import {Col, Row} from 'reactstrap'
import styled from 'styled-components'
import {FaPhone, FaWrench, FaEnvelope, FaDollarSign} from 'react-icons/fa'
import Link from '../link'
import {SiteConfig} from '../../data/siteConfig'

let StyledSection = styled.div`
   background: #312783;
   color: white;
   height: 125px;
   display: flex;
   align-items: center;
   overflow: hidden;
   padding: 0 1.5rem;
   transition-duration: 0.5s;

   border-right: 1px solid #312783;
   border-bottom: 1px solid #312783;
   @media ${props => props.theme.breakpoints.lg} {
     border-bottom: none;
   }
   h3 {
     font-size: 1.3rem;
   }
   h5 {
     font-size: 0.9rem;
   }
   h6 {
     font-size: 0.8rem;
   }
   @media ${props => props.theme.breakpoints.xxl} {
     padding: 0 2rem;
     h3 {
       font-size: 1.5rem;
     }
     h5 {
       font-size: 1.25rem;
     }
     h6 {
       font-size: 1rem;
     }
   }
   @media ${props => props.theme.breakpoints.xl} {
     &.clip {
       &> div {
         position: relative;
         z-index: 2;
       }
       &:before {
         content: '';
         transition-duration: 0.5s;
         border-top: 100px solid transparent;
         border-right: 50px solid #1d71b8;
         border-bottom: 100px solid transparent;
         right: 100%;
         position: absolute;
         height: 100%;
       }
     }
   }
   &.blue {
     background: #1d71b8;
   }
   &:hover {
     color: #1d71b8;
     background: white;
     &.clip:before {
       border-right: 50px solid white;
     }
     .circle-border {
       border: 1px dotted #1d71b8;
     }
   }
   .circle-border {
     border: 1px dotted white;
     border-radius: 50%;
     width: 50px;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     @media ${props => props.theme.breakpoints.xxl} {
       width: 60px;
       height: 60px;
     }
   }
   .cta-icon {
     font-size: 20px;
     @media ${props => props.theme.breakpoints.xxl} {
       font-size: 30px;
     }
   }
`

let BorderRow = styled(props => <Row {...props}/>)`
  @media ${props => props.theme.breakpoints.lg} {
    border-right: 1px solid #312783;
    border-bottom: 1px solid #312783;
  }
`

let Section = ({className, children, Icon = FaPhone, url}) => (
  <Col sm="6" lg={3}>
    <Link to={url} className="unstyled">
      <StyledSection className={className}>
        <Row className="d-flex align-items-center">
          <Col xs={2}>
            <div className="circle-border"><Icon className="cta-icon"/></div>
          </Col>
          <Col xs={10}>
            <div className="ml-4">
              {children}
            </div>
          </Col>
        </Row>
      </StyledSection>
    </Link>
  </Col>
)

let Cta = () => {
  let {phone, email} = SiteConfig()

  return (
  <div>
    <BorderRow className="no-gutters overflow-hidden">
      <Section Icon={FaWrench} url="/booking">
        <h5 className="m-0">Instant Booking</h5>
        <h3 className="m-0">Book Online Now</h3>
      </Section>
      <Section className="clip blue" url={`tel:${phone.replace(/ /g, '')}`}>
        <h5 className="m-0">Call Us 24/7</h5>
        <h3 className="m-0">{phone}</h3>
      </Section>
      <Section className="blue" Icon={FaEnvelope} url={`mailTo:${email}`}>
        <h6 className="m-0">{email}</h6>
        <h3 className="m-0">Email Us</h3>
      </Section>
      <Section className="blue" Icon={FaDollarSign} url="/booking">
        <h5 className="m-0">Free Quotes</h5>
        <h3 className="m-0">Request a quote</h3>
      </Section>
    </BorderRow>
  </div>
)}

export default Cta
