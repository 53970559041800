import React from "react"
import {Container, Col, Row} from 'reactstrap'
import styled from 'styled-components';
import {GatsbyImage} from 'gatsby-plugin-image'
import { StaticQuery, graphql } from "gatsby"
import Link from '../link'

let StyledFeature = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;
  display: flex;
  height: 100%;
  flex-direction: column;
  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.20);
  }
`

let BlogPost = ({blogPost}) => (
  <Col md={6} lg={4} className="mb-3">
    <Link to={`/blog/${blogPost.slug.current}`} className="unstyled">
      <StyledFeature>
        <GatsbyImage
          image={blogPost.bannerImage.asset.gatsbyImageData}
          alt={blogPost.bannerImage.alt}
          style={{height: '250px'}}
        />
        <div className="p-3">
          <h5 className="font-weight-bold">{blogPost.title}</h5>
          <div className="text-secondary">
            <p>{blogPost.excerpt}</p>
            <small>{blogPost._createdAt}</small>
          </div>
        </div>
      </StyledFeature>
    </Link>
  </Col>
)

const BlogPosts = ({heading}) => {
  return (
    <StaticQuery
      query={graphql`
        query BlogPostQuery {
          allSanityBlogPost(sort: {fields: _createdAt, order: DESC}) {
            edges {
              node {
                id
                slug {
                  current
                }
                title
                excerpt
                _createdAt(fromNow: true)
                bannerImage {
                  alt
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="pageComponent">
          <Container className="py-4">
            <h2 className="text-center mb-5">{heading}</h2>
            <Row>
              {data.allSanityBlogPost.edges.map(blogPost => (
                <BlogPost
                  blogPost={blogPost.node}
                  key={blogPost.node.id}
                />
              ))}
            </Row>
          </Container>
        </div>
      )}
    />
  )
}

export default BlogPosts
