import React from "react";
import GoogleMapReact from 'google-map-react';
import {GooglePlace} from '../../data/googlePlace'
import {FaMapMarkerAlt} from 'react-icons/fa'

const MapMarker = () => (
  <div style={{position: 'relative'}}>
    <FaMapMarkerAlt
      size={50}
      className="text-primary fa-4x"
      style={{position: 'absolute', bottom: 0, left: '-50%', transform: 'translateX(-50%)'}}/>
  </div>
)

const GoogleMap = ({
  height = '400px', 
  lat,
  lng
}) => {
  let {geometry} = GooglePlace()

  const defaultProps = {
    center: {
      lat: lat ? lat : geometry.location.lat,
      lng: lng ? lng : geometry.location.lng
    },
    zoom: 11
  };

  return (
    <div style={{ height: height, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyC5pHYgUPTxHrzmU-tyRwyEas4FOC0K1vQ',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <MapMarker
          lat={lat ? lat : geometry.location.lat}
          lng={lng ? lng : geometry.location.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMap
