import React from 'react'
import { FaQuoteLeft } from 'react-icons/fa';
import { withTheme } from 'styled-components'

const Blockquote = ({children, theme}) => (
  <div className="d-flex pt-1">
    <div className="mr-4">
      <FaQuoteLeft size={25} color={theme.primary}/>
    </div>
    <div>
      <p style={{fontSize: '17px'}}>
        {children}
      </p>
    </div>
  </div>
)

export default withTheme(Blockquote)
