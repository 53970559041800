import sanityConfig from '../sanityConfig'
import imageUrlBuilder from '@sanity/image-url'

export function buildImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

const builder = imageUrlBuilder(sanityConfig)

export function imageUrlFor (source) {
  return builder.image(source)
}
