import React from 'react'
import Btn from '../btn'

let Button = ({pageRoute, title, route}) => {
  let link = route
  if (pageRoute && pageRoute.slug && pageRoute.slug.current) {
    link = pageRoute.slug.current
  }
  return (
    <div className="text-center">
      <Btn to={link}>{title}</Btn>
    </div>
  )
}

export default Button
