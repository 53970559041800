import React from 'react'
import SanityImage from './sanityImage'

const Sponsor = ({name, image}) => (
  <div className="d-flex align-items-center justify-content-center">
    <SanityImage
      image={image.asset}
      style={{width: '9rem'}}
    />
  </div>
)

export default Sponsor
