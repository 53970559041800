import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'

let FadeInStyle = styled.div`
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.4s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`

const FadeIn = ({children, ...props}) => {
  let [active, setActive] = useState(true)
  return (
    <VisibilitySensor active={active} onChange={(isVisible) => setActive(!isVisible)} partialVisibility={true}>
      <FadeInStyle className={active ? 'invisible' : 'is-visible'} {...props}>
        {children}
      </FadeInStyle>
    </VisibilitySensor>
  );
}

export default FadeIn
