import React from 'react'
import Button from '../btn'

const Buttons = ({buttons}) => (
  <>
    {buttons ? buttons.map(button => (
      <Button
        className="mr-2"
        to={button.pageRoute ? '/'+button.pageRoute.slug.current : button.route}
        key={button._key}
        color={button.class}
      >
        {button.title}
      </Button>
    )) : ''}
  </>
)

export default Buttons
