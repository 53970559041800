import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import BlockContent from './index'
import styled, { withTheme } from 'styled-components'
import {FaPlus, FaMinus} from 'react-icons/fa'

let CollapseStyle = styled.div`
  position: relative;
  margin-top: 1.75rem;
  .collapseHeading {
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 1rem;
    position: relative;
    padding: 1rem 0;
    &:hover {
      cursor: pointer;
      color: ${props => props.theme.primary}
    }
    p {
      font-weight: bold;
      margin: 0;
      font-size: 1.1rem;
      margin-right: 3rem;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
    }
  }
`

let CollapseItem = ({heading, text, theme}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const Icon = isOpen ? FaMinus : FaPlus

  return (
    <CollapseStyle>
      <div className="collapseHeading" onClick={toggle}>
        <p>{heading}</p>
        <Icon color={theme.primary} size={20}/>
      </div>
      <Collapse isOpen={isOpen}>
        <BlockContent blocks={text || []} />
      </Collapse>
    </CollapseStyle>
  )
}

export default withTheme(CollapseItem)
