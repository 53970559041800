import React from 'react'
import Buttons from './buttons'
import styled from 'styled-components'
import {
  FaHome,
  FaBuilding,
  FaLock,
  FaHammer,
  FaUserMd,
  FaBriefcase,
  FaCar
} from 'react-icons/fa'
import BlockContent from '../block-content'
import FadeIn from '../fadeIn'

let CardStyling = styled.div`
  background: white;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  &.p-2rem {
    padding: 2rem;
  }
  @media ${props => props.theme.breakpoints.md} {
    height: 100%;
  }
  .circle {
    background: ${props => (props._style === 'primary') ? props.theme.primary : props.theme.secondary};
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: absolute;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .circle + .d-flex.h-100 {
    margin-top: 1rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  figure {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  ul {
    list-style-type: none;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }
`

const Card = ({title, text, path, buttons, style, icon, classNames = "pt-4"}) => {
  let icons = {
    home: FaHome,
    lock: FaLock,
    briefcase: FaBriefcase,
    hammer: FaHammer,
    userMd: FaUserMd,
    building: FaBuilding,
    car: FaCar
  }
  const Icon = icons[icon]
  return (
    <FadeIn style={{display: 'flex', flex: '1', height: '100%'}}>
      <div className="mb-2 w-100">
        <CardStyling _style={style} className={classNames}>
          {icon && <div className="circle">
            <Icon color="white" size="20"/>
          </div>}
          <div className="h-100 d-flex flex-column">
            <h4 className="text-center mb-3 px-4">{title}</h4>
            <div className="text-secondary mb-auto px-4">
              <BlockContent blocks={text || []} />
            </div>
            {buttons && (
              <div className="text-center pb-5">
                <Buttons buttons={buttons}/>
              </div>
            )}
          </div>
        </CardStyling>
      </div>
    </FadeIn>
  )
}

export default Card
