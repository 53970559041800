import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Button from '../btn'
import { useLocation } from '@reach/router';
import { useForm } from "react-hook-form"
import axios from 'axios'
import qs from 'qs'
import {SiteConfig} from '../../data/siteConfig'
import { navigate } from "gatsby"

async function sendToServer(formBody, totalDealerWebsiteId, totalDealerBusinessId) {
  await axios({
    method: 'post',
    url: 'https://dashboard.totaldealer.com.au/leads',
    data: qs.stringify({
      lead: {
        dealership_id: totalDealerBusinessId,
        website_id: totalDealerWebsiteId,
        url: window.location.href,
        category: 'General Enquiry',
        ...formBody
      },
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
  })
}

const PageWrapper = ({children}) => (
  <div className="pageComponent imageSection">
    <Container className="py-5">
      <Row className="d-flex justify-content-center">
        <Col md={6}>
          {children}
        </Col>
      </Row>
    </Container>
  </div>
)

const SectionWrapper = ({children}) => (
  <>
    {children}
  </>
)

const Form = ({name, formItems, type}) => {
  let {
    totalDealerWebsiteId,
    totalDealerBusinessId
  } = SiteConfig()
  let Wrapper = (type === 'page') ? PageWrapper : SectionWrapper
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    sendToServer(data, totalDealerWebsiteId, totalDealerBusinessId)
      .then(e => {
        navigate("/")
      })
  }
  const location = useLocation();
  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="form-name" value={name}/>
        {formItems.map(formItem => (
          <div className="form-group">
            <input
              className="form-control"
              placeholder={formItem.name}
              name={formItem.name}
              key={formItem._key}
              {...register(formItem.name, { required: true })}
            />
          </div>
        ))}
        <input name="url" value={location.href} type="hidden"/>
        <Button type="submit">Submit</Button>
      </form>
    </Wrapper>
  )
}

export default Form
