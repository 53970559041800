import React from 'react';
import BlockContent from './index'
import styled from 'styled-components'

let ColumnStyle = styled.div`
  margin-bottom: 1rem;
  > div {
    columns: 2;
    @media ${props => props.theme.breakpoints.lg} {
      columns: 3;
    }
  }
  .check {
    display: inline-block;
    width: 100%;
    svg {
      width: 20%;
      min-width: 20%;
      @media ${props => props.theme.breakpoints.lg} {
        width: 15%;
        min-width: 15%;
      }
    }
    > div {
      width: 80%;
      @media ${props => props.theme.breakpoints.lg} {
        width: 85%;
      }
    }
  }
`

let CollapseItem = ({heading, text, theme}) => (
  <ColumnStyle>
    <BlockContent blocks={text || []} />
  </ColumnStyle>
)

export default CollapseItem
