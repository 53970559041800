import React from 'react';
import BlockContent from './index'

let TextAlignment = ({alignment, text}) => (
  <div className={`text-${alignment}`}>
    <BlockContent blocks={text || []} />
  </div>
)

export default TextAlignment
