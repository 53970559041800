import React from "react";
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import {Container} from 'reactstrap'
import Slider from "react-slick";
import { FaQuoteLeft } from 'react-icons/fa';

import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

let Dots = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    & > li > button {
      height: 15px;
      width: 15px;
      background: ${props => props.theme.primary};
      border-radius: 50%;
      border: none;
      padding: 0;
      margin: 0 3px;
      font-size: 0;
      &:focus {
        outline: 0;
      }
    }
    .slick-active {
      button {
        background: transparent;
        border: 2px solid ${props => props.theme.primary};
      }
    }
  }
`

let Review = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  p {
    font-size: 13px;
  }
  h6 {
    color: ${props => props.theme.primary}
  }
`

let SliderStyle = styled.div`
  .slick-slide:not(.slick-center) {
    opacity: 0.6;
    transform: scale(0.9);
    cursor: pointer;
  }
  .slick-slide {
    transition-duration: 0.5s;
    height: auto;
    & > div {
      height: 100%;
    }
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

const GoogleReviews = styled.div`
  h3 {
    color: ${props => props.theme.primary};
  }
`

const Testimonials = ({heading}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    className: "center",
    arrows: false,
    focusOnSelect: true,
    dotsClass: 'custom-dots',
    appendDots: (dots) => (
      <Dots>
        <ul>{dots}</ul>
      </Dots>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <StaticQuery
      query={graphql`
        query {
          googlePlacesPlace {
            name
            rating
            childrenGooglePlacesReview {
              author_name
              text
              rating
              profile_photo_url
              id
            }
            user_ratings_total
          }
        }
      `}
      render={({googlePlacesPlace}) => (
        <GoogleReviews className="pageComponent" style={{background: 'rgb(189, 189, 189)'}}>
          <Container className="py-5">
            <div className="text-center">
              <FaQuoteLeft size={40} className="mb-3"/>
              <h3>
                {heading}
              </h3>
              <h5 className="mb-4">
                Google Rating {googlePlacesPlace.rating}
              </h5>
            </div>
            <SliderStyle>
              <Slider {...settings}>
                {googlePlacesPlace.childrenGooglePlacesReview.map(review => (
                  <div className="px-1 pb-4 h-100" key={review.id}>
                    <Review className="shadow">
                      <img
                        src={review.profile_photo_url}
                        height="50px"
                        width="50px"
                        className="mb-2"
                        alt={review.author_name}
                      />
                      <h6 className="text-center font-weight-bold">
                        {review.author_name}
                      </h6>
                      <p className="text-center font-italic m-0 text-secondary">
                        {review.text}
                      </p>
                    </Review>
                  </div>
                ))}
              </Slider>
            </SliderStyle>
          </Container>
        </GoogleReviews>
      )}
    />
  )
}

export default Testimonials;
