import React from 'react'
import ComponentSelector from '../componentSelector'

const RepeatableSection = ({content}) => (
  <>
    {content && content.map(c => (
      <ComponentSelector _rawContent={[c]} key={c._key}/>
    ))}
  </>
)

export default RepeatableSection
